<template>
  <div class="max-w-[1348px] mx-auto">
    <UCard
      :ui="{
        body: { padding: 'px-16 py-8 sm:px-16 sm:py-8' },
      }"
    >
      <div class="flex justify-between items-center flex-wrap lg:flex-nowrap gap-8">
        <div>
          <div class="flex gap-2 items-center mb-3">
            <img preload src="/assets/images/enterprise.svg" />
            <p class="text-xl font-bold">Enterprise</p>
          </div>
          <h3 class="text-[40px] leading-tight font-medium text-black mb-3">
            Looking for something <br />
            more tailored?
          </h3>
          <p class="text-[#595959 text-lg mb-8">
            Perfect for large teams, this plan offers
            <span class="font-bold">unlimited seats</span> and adapts to your
            business needs.
          </p>
          <NuxtLink
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSd7vYdcjfmLYoMk6-T9DKRWNYh3QCR-b3m_RTQ_aKTz6ZFVEg/viewform"
          ><UButton size="md">Contact Sales</UButton></NuxtLink>
        </div>
        <img
          preload
          class="w-[500px] mx-auto"
          src="/assets/images/billing-contact.png"
        />
      </div>
    </UCard>
  </div>
</template>
