<template>
  <div>
    <div @click="openModal">
      <slot></slot>
    </div>

    <UModal
      v-if="open" v-model="open" :ui="{ fullscreen: 'h-max' }" fullscreen
      :transition="false"
    >
      <div class="p-4 mb-4">
        <div class="flex justify-end">
          <UButton
            color="gray"
            size="sm"
            variant="ghost"
            icon="i-heroicons-x-mark-20-solid"
            @click="open = false"
          />
        </div>
        <div class="mb-[50px]">
          <h1 class="text-3xl mb-4 text-center text-black">
            Upgrade your plan
          </h1>
          <p class="text-center mb-8 max-w-[600px] text-base mx-auto">
            Get full access to all Leanbase features with every new account, so
            you can explore and decide if it’s the perfect solution for you.
          </p>
          <div class="mx-auto">
            <UTabs
              :items="items"
              :ui="{
                wrapper: 'relative space-y-9 text-center',
                list: { width: 'w-fit' },
              }"
            >
              <template #item="{ item }">
                <div
                  v-if="packageList.length && !loading"
                  class="flex gap-4 text-left justify-center flex-wrap"
                >
                  <Card
                    v-for="pack in packageList"
                    :key="pack.id"
                    :package="pack"
                    :current-package-name="currentPackageName"
                    :type="item.key"
                    :disabled="creatingSession"
                    :subscription="subscription"
                    @upgrade="handleUpgrade"
                  />
                </div>
                <div
                  v-else
                  class="flex gap-4 text-left justify-center flex-wrap"
                >
                  <USkeleton
                    v-for="n in 4"
                    :key="n"
                    class="w-[325px] h-[318px]"
                  />
                </div>
              </template>
            </UTabs>
          </div>
        </div>
        <Contact class="mb-[90px]"></Contact>
        <FeatureList></FeatureList>
      </div>
    </UModal>
  </div>
</template>

<script setup lang="ts">
import Card from './Card.vue'

import type { BillingCycle } from '#billing/constant'
import { BillingPageTarget } from '#billing/constant'

const { currentWorkspace } = useWorkspaceSharedState()
const { $analytics } = useNuxtApp()
const { result, loading, load } = usePackagesQuery()
const {
  mutate,
  error,
  loading: creatingSession,
} = useCreateSubscribePackageSessionMutation()
const { subscription, getBillingUrl } = useWorkspaceSubscription()

type Props = {
  autoOpen?: boolean,
}

const props = defineProps<Props>()

const currentPackageName = computed(() => {
  if (subscription.value) {
    return subscription.value?.package.name
  }

  return ''
})

const openModal = () => {
  open.value = true
  load()

  // Send analytics
  $analytics.track('billing.package_modal_opened')
}

const handleUpgrade = async (id: string, billingCycle: BillingCycle) => {
  // Send analytics
  $analytics.track('billing.package_selected', {
    packageId: id,
    billingCycle,
  })

  // call api to select package
  const data = await mutate({
    packageId: id,
    workspaceId: currentWorkspace.value.id,
    billingCycle,
  })

  if (data?.data?.createSubscribePackageSession.success) {
    const url = data.data?.createSubscribePackageSession.url
    // redirect to checkout page if url is available
    if (url) {
      window.location.href = data.data?.createSubscribePackageSession.url
    } else {
      // Redirect to billing #success
      const url = getBillingUrl(BillingPageTarget.SUCCESS)
      window.location.href = url
      window.location.reload()
    }
  } else {
    // show error
    handleSingleGraphQLError(error.value, true)
  }
}

const packageList = computed(() => {
  return result.value?.packages ?? []
})
const items = [
  {
    key: 'MONTHLY',
    label: 'Pay monthly',
  },
  {
    key: 'YEARLY',
    label: 'Pay yearly (save 20%)',
  },
]

const open = ref(false)

onMounted(() => {
  if (props.autoOpen) {
    openModal()
  }
})
</script>
