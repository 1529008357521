<template>
  <UCard
    :ui="{
      ring: isAdvanced ? 'ring-[#00C16A]' : '',
    }" class="max-w-[325px]"
  >
    <div class="mb-1.5 flex gap-2">
      <img
        v-if="isAdvanced"
        preload
        class="w-[22px] h-[22px]"
        src="/assets/images/popular-star.png"
      />
      <span class="font-bold text-xl">{{ package.name }}</span>
      <UBadge
        v-if="isAdvanced"
        class="text-lime-800"
        :ui="{ color: { gray: { solid: 'ring-[#84CC16] bg-[#F7FEE7]' } } }"
        color="gray"
        size="sm"
        variant="solid"
      >
        Most popular
      </UBadge>
    </div>

    <p class="mb-6 text-base text-gray-500 w-fit">
      {{ plan?.description }}
    </p>
    <div class="h-24 mb-8">
      <div class="flex items-end gap-1 mb-2">
        <p class="text-[40px] leading-none font-medium">
          ${{
            type === 'MONTHLY'
              ? Number(package.monthlyPrice)
              : (Number(package.yearlyPrice)/12).toFixed(0)
          }}
        </p>

        <p class="text-base truncate w-fit">
          per month
        </p>
        <UTooltip
          :popper="{ placement: 'top' }"
          :text="`Each additional user is available for: $${package.extraUserPrice} per month.`"
          :ui="{
            width: 'max-w-md'
          }"
        >
          <UButton
            class="rounded-full hover:text-primary hover:bg-primary-100"
            icon="i-heroicons-information-circle"
            size="2xs"
            color="gray"
            square
            variant="ghost"
          />
        </UTooltip>
      </div>
      <span
        v-if="isAdvanced || isPro"
        class="text-[#016538] font-medium"
      >~ ${{
        (type === 'MONTHLY'
          ? (Number(package.monthlyPrice) / package.includedUsers).toFixed(2)
          : (Number(package.yearlyPrice)/(package.includedUsers*12)).toFixed(2))
      }}
        only per user</span>
      <p v-if="type === 'YEARLY'" class="mt-1">billed once yearly</p>
    </div>
    <UButton
      size="md"
      :color="isAdvanced ? 'primary' : 'white'"
      variant="solid"
      block
      :disabled="isDisabled"
      @click="handleUpgrade"
    >
      {{ buttonLabel }}
    </UButton>
  </UCard>
</template>

<script setup lang="ts">
import type { BillingCycle } from '#billing/constant'
import type { Package, Subscription } from '#billing/types'

type Props = {
  package: Package
  type: BillingCycle
  disabled: boolean
  currentPackageName: string
  subscription?: Subscription | false | null
}
const emit = defineEmits<{
  (e: 'upgrade', id: string, billingCycle: BillingCycle): void
}>()
const props = defineProps<Props>()

const PLAN_DESCRIPTION = [
  {
    index: 0,
    name: 'Starter',
    description: 'Perfect for small teams with up to 15 members.',
  },
  {
    index: 1,
    name: 'Standard',
    description: 'Designed for growing teams, supporting up to 30 members.',
  },
  {
    index: 2,
    name: 'Advanced',
    description: 'Ideal for larger teams, managing up to 100 members.',
  },
  {
    index: 3,
    name: 'Pro',
    description: 'Built for scaling businesses, allowing up to 200 members.',
  },
]
const plan = computed(() => {
  return PLAN_DESCRIPTION.find((plan) => plan.name === props.package.name)
})
const isDisabled = computed(() => {
  // If disabled prop is true, return true
  if (props.disabled) {
    return true
  }

  // If this is a placeholder subscription, return false
  if (props.subscription && props.subscription.placeholder) {
    return false
  }

  // If the current package name is the same as the package name, return true
  return props.currentPackageName === props.package.name
})

const buttonLabel = computed(() => {
  if ((props.currentPackageName === props.package.name) && (!props.subscription || (props.subscription && !props.subscription.placeholder))) {
    return 'Current plan'
  }

  const currentPlanIndex = PLAN_DESCRIPTION.findIndex(
    (plan) => plan.name === props.currentPackageName
  )
  const packagePlanIndex = PLAN_DESCRIPTION.findIndex(
    (plan) => plan.name === props.package.name
  )

  return packagePlanIndex < currentPlanIndex ? 'Downgrade' : 'Upgrade'
})

const isAdvanced = computed(() => {
  return props.package.name === 'Advanced'
})

const isPro = computed(() => {
  return props.package.name === 'Pro'
})

const handleUpgrade = () => {
  emit('upgrade', props.package.id, props.type)
}
</script>
