<template>
  <div class="max-w-[1348px] mx-auto">
    <p class="text-center text-3xl text-black mb-6">All features will be available across all plan</p>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <FeatureItem v-for="feature in featureList" :key="feature.title" :feature="feature"></FeatureItem>
    </div>
  </div>
</template>

<script setup lang="ts">
const featureList = ref([
  {
    title: 'Project management',
    description: 'Manage projects seamlessly with Leanbase track tasks, deadlines, and team roles across Kanban, Scrum, or Waterfall models.',
    img: '/assets/images/project-management.png',
    features: ['Create unlimited tasks', 'Preview all board views', 'Custom fields', 'Timesheet & tracking', 'Report', 'Task explorer', 'Program']
  },
  {
    title: 'Workflows and automation',
    description: `Connect every team's work to company objectives, then track progress with real-time data.`,
    img: '/assets/images/workflow-automation.png',
    features: ['Sprint', 'Release', 'Rules', 'Settings Pack', 'Template']
  },
  {
    title: 'Admin and security',
    description: 'Create and manage teams and team settings to ensure that everyone has access to the right information',
    img: '/assets/images/admin-security.png',
    features: ['Admin console', 'Permissions and privacy controls', 'Role permission', 'Team management', 'Data security', 'Guest management']
  }
])
</script>
